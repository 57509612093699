body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(255, 255, 255, 0.2);
}

body::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(255, 255, 255, 0.2);
}

body::-webkit-scrollbar-thumb
{
	background-color: white;
}
