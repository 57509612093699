@import "scrollbar.css";
@import url('https://fonts.googleapis.com/css?family=Raleway:200,400&display=swap');

:root {
    --body-bg: #74b9ff;
    --body-color: white;
}


html {
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    background-color: var(--body-bg);
    color: var(--body-color);
    font-family: 'Raleway', sans-serif;
    h1 {
        font-weight: 200;
    }
}

.main {
    text-align: center;
    .jumbotron {
        background: transparent;
    }
}

.badges {
    margin-top: 20px;
    margin-bottom: 10px;
}

.npm-install {
    background: rgb(30, 30, 30);
    color: rgb(220, 220, 220);
    padding: 5px 10px 5px 10px;
    border-radius: 1em;
    font-family: monospace;
}

.github-corner:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out
}

@keyframes octocat-wave {

    0%,
    100% {
        transform: rotate(0)
    }

    20%,
    60% {
        transform: rotate(-25deg)
    }

    40%,
    80% {
        transform: rotate(10deg)
    }
}

@media (max-width:500px) {
    .github-corner:hover .octo-arm {
        animation: none
    }

    .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out
    }
}

.toplinks {
    a {
        color: white;
        transition: border-bottom ease-in-out 0.5s;
        border-bottom: 1px solid white;
        text-decoration: none;
        &:hover {
            border-bottom: 0px solid transparent;
        }
        &:visited {
            color: white;
        }
    }
    margin-top: 20px;
}

.toplink {
    float: left;
    margin-left: 20px;
}

footer {
    margin-top: 80px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.8em;
    a {
        color: white;
        transition: border-bottom ease-in-out 0.5s;
        border-bottom: 1px solid white;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: white;
            border-bottom: 0px solid transparent;
        }
        &:visited {
            color: white;
        }
    }
}

#haredo-logo {
    width: 200px;
    margin-left: 30px;
}
